import { Box, Text } from '@mantine/core'

const Row = ({ colName, colInfo }) =>
{
  return (
    <Box className='flex flex-row items-center flex-wrap gap-1 w-full px-1'>
      <Text>{ colName }</Text>
      <Text className='text-left'>{ colInfo }</Text>
    </Box>
  )
}

export default Row